import { format } from 'date-fns'

export const replaceLinkS3 = link => {
  if (!link || link.length === 0) return null
  const baseUrlS3 = process.env.VUE_APP_BASE_URL_S3
  const indexOf = link.indexOf(baseUrlS3)
  if (indexOf > -1) {
    return link.replace(baseUrlS3, '')
  }
  return null
}

export const formatAmount = (amount, options = {}) => {
  if (Number.isNaN(Number(amount))) return 0
  return Intl.NumberFormat('en-US', options).format(amount)
}

export const formatDateTimeAustralia = (value, options) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-AU', {
    timeZone: 'Australia/Sydney',
    ...options,
  }).format(new Date(value))
}

export const formatEventDate = value => {
  if (!value) return value
  const todayFormat = { day: 'numeric', month: 'numeric', year: 'numeric' }
  const dateFormat = { day: 'numeric', month: 'numeric', year: 'numeric' }
  const timeFormat = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }
  const isToday = formatDateTimeAustralia(value, todayFormat) === formatDateTimeAustralia(new Date().toISOString(), todayFormat)
  const date = formatDateTimeAustralia(value, dateFormat)
  const time = formatDateTimeAustralia(value, timeFormat)
  return `${isToday ? 'Today' : date} @${time}`
}

export function formatCardNumber(cardNumber) {
  // cardNumber = cardNumber.replace(/\D/g, '')
  if (!cardNumber) return ''
  cardNumber = cardNumber
    .split('')
    .map((c, i) => {
      if (i === 4 || i === 5) return 'X'
      return c
    })
    .join('')
  return cardNumber.replace(/(.{4})/g, '$1 ')
}

export const formatTime = (date, f = 'HH:mm:ss') => {
  if (!date) return ''
  const d = new Date(date)
  return format(d, f)
}

export const formatDateTimeTracking = value => {
  if (!value) return
  const dateFormat = { day: 'numeric', month: 'numeric', year: 'numeric' }
  const timeFormat = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  }
  const date = formatDateTimeAustralia(value, dateFormat)
  const time = formatDateTimeAustralia(value, timeFormat)

  return `${date ?? '-'} ${time ?? '-'}`
}

export const splitPhoneNumber = (phoneNumber, prefix) => {
  if (!prefix) {
    prefix = '+61'
  }
  if (phoneNumber) {
    const ind = phoneNumber.indexOf(prefix)
    if (ind > -1) {
      phoneNumber = phoneNumber.replace(prefix, '')
    }
  }

  return {
    phoneNumber,
    prefix,
  }
}

export const formatPrefixPhone = phoneNumber => {
  if (!phoneNumber) return phoneNumber

  if (phoneNumber[0] === '0') {
    return phoneNumber.replace('0', '')
  }
  return phoneNumber
}

export const formatDate = (date, f = 'yyyy-MM-dd') => {
  if (!date) return ''
  const d = new Date(date)
  return format(d, f)
}

export const formatDateLocal = (date, f = 'dd-MM-yyyy') => {
  if (!date) return ''
  const d = new Date(date)
  return format(d, f)
}
export const formatISO = date => {
  if (!date) return ''
  const d = new Date(date)
  return d?.toISOString()
}

export const formatDateTimeLocal = (date, f = 'HH:mm:ss aa dd-MM-yyyy') => {
  if (!date) return ''
  const d = new Date(date)
  return format(d, f)
}

export const formatDateUTC = date => {
  if (!date) return ''
  return new Date(date)?.toISOString()
}
export const formatDateSend = date => {
  if (!date) return ''
  const d = formatDateUTC(date)
  return d.slice(0, 19)
}

export const formatDateQuery = (date, f = "yyyy-MM-dd'T'HH:mm:ss") => {
  if (!date) return ''
  // const d = new Date(date)
  return format(date, f)
}

export const convertTZ = date => {
  if (!date) return
  const d = new Date(date).toLocaleString('en-US', { timeZone: 'Australia/Sydney' })
  return new Date(d)
}

export function getOrdinalSuffix(number) {
  const suffixes = ['th', 'st', 'nd', 'rd']
  const lastDigit = number % 10
  const lastTwoDigits = number % 100

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) return `${number}th`
  if (suffixes[lastDigit]) return number + suffixes[lastDigit]
  return `${number}th`
}

export function extractRank(string) {
  const LIMIT_CHARACTER = 16
  const character = string.length > LIMIT_CHARACTER ? LIMIT_CHARACTER : string.length
  const parts = string.split('-')
  const remainingLength = character - parts.at(-1).length - 1
  const truncatedString = parts.join('-').slice(0, remainingLength)
  return `${truncatedString}-${parts.at(-1)}`
}

export function getObjectChange(newObj, oldObj) {
  const result = {}
  Object.keys(oldObj).forEach(key => {
    // eslint-disable-next-line no-prototype-builtins
    if (newObj.hasOwnProperty(key) && oldObj.hasOwnProperty(key)) {
      if (JSON.stringify(newObj[key]) !== JSON.stringify(oldObj[key])) {
        result[key] = newObj[key]
      }
    }
  })
  return result
}

export function getShortName(string) {
  if (!string) return ''
  const words = string.split(' ')

  const manipulatedWords = words.map((word, index) => {
    if (index === 1) return word.substring(0, 2)
    return word
  })

  const result = manipulatedWords.join(' ')
  return result.toUpperCase()
}

export const formatCurrencyBuiltInFunc = value => {
  if (typeof value === 'number' || typeof value === 'string') {
    return Number(value).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
  }
  return ''
}

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

export function toCardinal(num) {
  const ones = num % 10
  const tens = num % 100

  if (tens < 11 || tens > 13) {
    // eslint-disable-next-line default-case
    switch (ones) {
      case 1:
        return `${num}st`
      case 2:
        return `${num}nd`
      case 3:
        return `${num}rd`
    }
  }

  return `${num}th`
}

export const convertTimeStart = val => {
  if (!val) return ''
  return new Date(`${val} 00:00:00`).toISOString()
}

export const convertTimeEnd = val => {
  if (!val) return ''
  return new Date(`${val} 23:59:59`).toISOString()
}

export function convertSportCode(val) {
  // eslint-disable-next-line default-case
  switch (val) {
    case 'AFL':
      return 'australian_rules'
    case 'NRL':
      return 'rugby_league'
    case 'NFL':
      return 'american_football'
    case 'NBA':
      return 'basketball'
    case 'BBL':
      return 'cricket'
  }

  return ''
}

export function shortenName(fullName) {
  const words = fullName.replace(/\s*\([^)]*\)/g, '').split(' ')
  if (words.length === 1) return words[0][0]?.toUpperCase()
  const abbreviation = words[0][0] + words[words.length - 1][0]
  if (!abbreviation) return ''

  return abbreviation?.toUpperCase()
}

export function formatMarketAlias(name) {
  // eslint-disable-next-line default-case
  if (name.toLocaleLowerCase().includes('disposals')) return `D${name.match(/(\d+\+)/)?.[0]}`
  if (name.includes('Winning Margin')) return 'WM'
  switch (name) {
    case 'Match Betting':
      return 'H2H'
    case 'Line':
      return 'L'
    case 'Player Goals - Anytime Goal Kicker':
      return 'AG'
    case 'Anytime Try Scorer':
      return 'AT'
    case 'To Have 25+ Disposals':
      return 'D25+'
    default:
      return shortenName(name)
  }
}

export function convertToUTC(dateString) {
  const [datePart, timePart] = dateString.split(' ')
  const [day, month, year] = datePart.split('/')
  const [hours, minutes] = timePart.split(':')

  const date = new Date(Date.UTC(year, month - 1, day, hours, minutes))

  return date.toISOString().replace('.000', '')
}
